<template>
  <v-card class="add-content-popup-card pb-7">
    <v-card-title class="px-6 pt-4 pb-4">
      Create New Creative
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row class="px-0 mx-2 mb-4">
        <v-col
          v-for="type in add_creative_types"
          :key="type.key"
          class="pa-0 mb-4"
          cols="12"
          sm="6"
        >
          <BoxTypeSelector
            class="mx-0"
            :header="type.header"
            :subheader="type.subheader"
            :icon="type.icon"
            :value="selected_content_type === type.key"
            :item-key="type.key"
            @input="selected_content_type = type.key"
          />
        </v-col>
      </v-row>
      <div v-if="!edit_mode">
        <validation-observer ref="textInput">
          <CustomTextInput
            v-model="creative_name"
            class="px-3"
            header="Creative Name"
            placeholder="Creative"
            :required="true"
          />
        </validation-observer>
      </div>

      <div v-else>
        <validation-observer ref="textInput">
          <CustomTextInput
            class="px-3"
            header="Creative Name"
            :value="active_creative.name"
            placeholder="Creative"
            :required="true"
            @change="active_creative_new_name = $event"
          />
        </validation-observer>
      </div>
      <v-row class="px-3">
        <v-col cols="12">
          <TagsInput
            v-model="selectedTags"
            :items="tagNames"
            header="Tags"
            placeholder="Select / Add Tag(s) for this Creative"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end align-center pa-0 mx-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        @click="onSave"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BoxTypeSelector from "@/sharedComponents/BoxTypeSelectorSmall";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import TagsInput from "@/sharedComponents/TagsInput";

export default {
  name: "SelectCreativeTypeDialog",
  components: {TagsInput, BoxTypeSelector, CustomTextInput },
  props: {
    active_creative: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      creative_name: null,
      active_creative_new_name: null,
      selected_content_type: "code",
      add_creative_types: [
        // {
        //   icon: "$list",
        //   header: "Paste in code",
        //   subheader: "Create a campaign by pasting your custom coded design",
        //   key: "code",
        // },
        // {
        //   icon: "$list",
        //   header: "Import from zip",
        //   subheader: "Upload a ZIP file that contains HTML code",
        //   key: "zip",
        // },
      ],
      selectedTags: [],
      tags: [],
      tagNames: [],
    };
  },
  computed: {
    edit_mode() {
      return !!(this.active_creative && this.active_creative.id) || false
    }
  },
  created() {
    this.getTags();
  },
  methods: {
    async onSave() {
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      if (this.active_creative_new_name) {
        this.$emit("edit-creative-name", this.active_creative_new_name);
      }

      // return creative type to calling component
      this.$emit("save", {
        selected_content_type: this.selected_content_type,
        creative_name: this.creative_name,
        tags: this.selectedTags.map((tag) => {
          const tagItem = this.tags.find((tagInstance) => tagInstance.name.toLowerCase() === tag.toLowerCase());

          return tagItem
              ? { id: tagItem.id } // use existing tag ID
              : { name: tag }; // create new tab
        }),
      });

      // close this dialog
      this.$emit("dismiss");
    },
    async getTags() {
      const response = await this.$rest.tags.getCollection({
        fields: ['id', 'name'],
        ignorePagination: true,
      }).catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.tags = response.data.items;
      this.tagNames = this.tags.map((tagItem) => tagItem.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-content-popup-card {
  border-radius: 5px !important;
}
</style>

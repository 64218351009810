<template>
  <v-list
    subheader
    style="height: 100%"
    :class="{
      'box-type-selector-small mx-1 pb-0 mb-3 mb-sm-0': true,
      'box-type-selector-small-selected': value,
    }"
  >
    <v-list-item
      link
      class="list-item pt-3 pb-1 pb-sm-3 pl-3 pr-5"
      @click="$emit('input', itemKey)"
    >
      <v-list-item-content class="pa-0">
        <div class="d-flex flex-nowrap align-center justify-start">
          <v-icon v-if="icon" dark class="pr-2">
            {{ icon }}
          </v-icon>
          <div class="selector__header">
            {{ header }}
          </div>
        </div>
        <div class="selector__subheader pt-1">
          {{ subheader }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "BoxTypeSelectorSmall",
  props: {
    header: {
      default: () => "Awesome header",
    },
    subheader: {
      default: () => "Awesome subheader with a looooong number of chars",
    },
    icon: {
      default: () => "",
    },
    value: {
      default: () => true,
    },
    itemKey: {
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.box-type-selector-small {
  border-radius: 3px !important;
  border: solid 1px #dceff9 !important;
  margin-top: 1px;
  margin-bottom: 1px;

  &-selected {
    border: solid 2px #2b84eb !important;
    background-color: rgba(43, 132, 235, 0.05) !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  .list-item {
    height: 100%;
    display: block;
    top: 0;
  }

  .selector {
    font-stretch: normal;
    font-style: normal;

    &__header {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      color: #241c15;
    }
    &__subheader {
      font-family: "Open Sans", sans-serif;
      font-size: 11px;
      font-weight: normal;
      line-height: normal;
      color: #66788e;
      font-weight: normal;
    }
  }
  .v-list-item__avatar {
    width: 29px !important;
  }
}
</style>

<template>
  <div class="custom-text-input">
    <div
      v-if="header"
      class="d-flex justify-space-between align-center flex-nowrap mb-4"
    >
      <div
        v-if="header"
        :class="headerClass || 'field__subheader'"
      >
        {{ header }}
      </div>
    </div>
    <validation-observer ref="textInput">
      <validation-provider
        v-slot="{ errors }"
        :rules="{
          required
        }"
        mode="eager"
      >
        <v-combobox
          v-model="chips"
          :items="items"
          :error-messages="errors"
          chips
          clearable
          :label="placeholder"
          multiple
          solo
          @input="$emit('input', chips)"
        >
          <template #selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "TagsInput",
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    header: {
      default: () => {},
      type: String,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => {},
      type: String,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    value: {
      default: () => [],
      type: Array,
    },
    items: {
      default: () => [],
      type: Array,
    },
  },
  data: () => ({
    chips: [],
  }),
  watch: {
    value(items) {
      if (!items || !items.length) {
        return;
      }

      this.chips = [...items];
    },
  },
  created() {
    this.chips.push(...this.value);
  },
  methods: {
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]

      this.$emit('input', this.chips);
      this.$emit('change', this.chips);
    },
  },
}
</script>

<style>
.theme--light.v-label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.4);
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-text-input"},[(_vm.header)?_c('div',{staticClass:"d-flex justify-space-between align-center flex-nowrap mb-4"},[(_vm.header)?_c('div',{class:_vm.headerClass || 'field__subheader'},[_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e()]):_vm._e(),_c('validation-observer',{ref:"textInput"},[_c('validation-provider',{attrs:{"rules":{
        required: _vm.required
      },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.items,"error-messages":errors,"chips":"","clearable":"","label":_vm.placeholder,"multiple":"","solo":""},on:{"input":function($event){return _vm.$emit('input', _vm.chips)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var attrs = ref.attrs;
      var item = ref.item;
      var select = ref.select;
      var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      Create New Creative
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <CustomTextInput
          header="Creative Name"
          :value="emailMessageName"
          class="flex-grow-1"
          placeholder="Creative"
          :maxlength="150"
          :required="true"
          @input="emailMessageName = $event"
        />
      </validation-observer>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          :disabled="!emailMessageName"
          @click="$emit('save', emailMessageName)"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
export default {
  name: "CloneEmailMessageDialog",
  metaInfo: {
    title: 'Clone email message'
  },
  components: { CustomTextInput },
  data() {
    return {
      emailMessageName: null
    };
  },
  methods: {
    async validate() {
      return await this.$refs.textInput.validate();
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },
  },
};
</script>
<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      {{ name || 'Image Preview' }}
    </v-card-title>
    <v-divider />
    <v-card-text class="px-6 pt-5" style="min-height: 300px">
      <div class="img-container d-flex align-center justify-center">
        <img :src="imageUrl">
      </div>
    </v-card-text>
    <v-card-actions
      class="divider-with-shadow d-flex justify-end px-6 pb-3 pt-3"
    >
      <a v-if="fullImageUrl" class="mr-4" :href="fullImageUrl" target="_blank">
        <v-btn height="34px" class="elevation-0 custom-button">View Full Size</v-btn>
      </a>
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="$emit('dismiss')"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ImagePreviewDialog",
  components: {
  },
  props: {
    imageUrl: {
      default: () => null,
    },
    fullImageUrl: {
      default: () => null,
    },
    name: {
      default: () => null,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped>
  a {
    text-decoration: none !important;
  }

  .img-container {
    height: 100%;
  }

  .img-container img {
    max-height: 100%;
    width: auto;
  }
</style>
